const theme = {
    palette: {
        primary: {
            main: '#00857D', // Primary color for highlights
            highlight: '#00857D', // Hover, Active...
            text: '#fff', // Text color with primary background
            subtext: 'rgba(255, 255, 255, 0.8)', // Hints and Stuff that's not important
            contrast: '#D3E9E5',
        },
        secondary: {
            main: '#FFCD02',
            highlight: '#FFCD02',
            text: '#000',
            subtext: 'rgba(0, 0, 0, 0.8)',
        },
        // Default main-content background (boxes)
        main: {
            background: '#ffffff',
            highlight: '#f1ebeb',
            text: 'rgba(0, 0, 0, 0.7)',
            subtext: 'rgba(0, 0, 0, 0.4)',
            textSecondary: '#FFFFFF',
        },
        // second.background-content background (Inputs, sidebar)
        second: {
            background: '#F5F5F5',
            highlight: '#ffffff',
            text: 'rgba(0, 0, 0, 0.65)',
            subtext: 'rgba(0, 0, 0, 0.5)',
        },
        alert: {
            error: {
                color: '#d64141',
                text: '#fff',
            },
            success: {
                color: '#009a73',
                text: '#fff',
            },
            info: {
                color: '#cdeefd',
                text: '#02587f',
            },
        },
    },
    button: {
        color: {
            hover: 'rgb(10, 10, 10, 0.2)',
        },
    },
    spacing: {
        default: 30,
        outer: 20,
        inner: 10,
        action: [7, 15],
    },

    border_radius: 0,
    transition: (element: string) =>
        `${element} 0.4s cubic-bezier(.25,.8,.25,1)`,
    components: {
        sidebar: {
            width: 300,
        },
        mainbar: {
            height: 64,
        },
        berechtigungsmatrix: {
            cellHeight: 32,
        },
    },
    typography: {
        text: {
            size: 14,
            weight: 400,
        },
        headline: {
            size: 22,
            weight: 400,
        },
    },
    shadows: {
        inset: 'inset 0 1px 1px 0 rgba(60,64,67,.08), inset 0 1px 3px 1px rgba(60,64,67,.16)',
        level0: '',
        level1: '',
        level2: '',
        default: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        button: '0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16)',
    },
    z_indexes: {
        select_option: 50,
        alert: 900,
        mainbar: 1000,
        sidebar: 999,
    },
    breakPoints: {
        slim: {
            value: 1024,
            query: `@media screen and (max-width: ${1024}px)`,
        },
    },
};

export type ITheme = typeof theme;

export default theme;
