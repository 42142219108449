export default class Formatter {
    static shorten(name: string, length: number): string {
        return name
            ? name.length > length
                ? name.substr(0, length) + '...'
                : name
            : '';
    }

    /**
     * Render date in a pleasing way
     *
     * @param laravel_date_time         datetime from laravel (YYYY-MM-DD HH:ii:ss
     * @param show_time                 whether to show time or date only
     * @returns {string}
     */
    static dateTime(
        laravel_date_time: string | Date | null,
        show_time = false
    ): string {
        if (!laravel_date_time) {
            return '-';
        }

        // Concert to Date
        const date =
            laravel_date_time instanceof Date
                ? laravel_date_time
                : new Date(Formatter.laravelToIEDate(laravel_date_time));

        // Show dash when below certain value (incorrect dates from DB)
        if (date.getFullYear() < 1900) {
            return '-';
        }

        const appendZero = (value: number) => (value < 10 ? '0' : '') + value;

        const d = appendZero(date.getDate());
        const m = appendZero(date.getMonth() + 1);
        const y = date.getFullYear();

        const h = appendZero(date.getHours());
        const s = appendZero(date.getMinutes());

        return `${d}.${m}.${y}` + (show_time ? `, ${h}:${s} Uhr` : '');
    }

    static decimals(dot_notation: number | string): string {
        return dot_notation.toString().replace('.', ',');
    }

    static currency(number: string | number | null): string {
        if (Number.isNaN(number) || number === null) {
            return '';
        }

        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(parseFloat(number.toString()));
    }

    static getRoleName(role: string) {
        return Voigt.roles[role];
    }

    static getPercentage(part: number, total: number): string {
        return total > 0
            ? ((part / total) * 100).toFixed(1).replace('.', ',')
            : '0,0';
    }

    static laravelToIEDate(timestring: string): string {
        return timestring.includes('T')
            ? timestring
            : timestring.replace(/-/g, '/');
    }

    static formatToMBWithThreeDecimal(size: number): number {
        return Math.round((size / 1000000) * 1000) / 1000;
    }

    static formatKBToMB = (
        kiloByte: number,
        decimals = 2,
        exact = true
    ): number => {
        if (!+kiloByte) {
            return 0;
        }
        const c: number = 0 > decimals ? 0 : decimals,
            d: number = Math.floor(Math.log(kiloByte) / Math.log(1024));

        if (!exact) {
            return parseInt((kiloByte / Math.pow(1000, d)).toFixed(c));
        }

        return parseFloat((kiloByte / Math.pow(1024, d)).toFixed(c));
    };

    static jaNein(value?: boolean | null): string {
        if (value !== false && value !== true) {
            return '-';
        }
        return value ? 'Ja' : 'Nein';
    }

    static capitalize(string?: string) {
        if (typeof string !== 'string') return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static recordTypeLabel(record: {
        referat: string;
        schadensklasse?: number;
    }): string {
        switch (true) {
            case record.referat === 'ordnungswidrigkeit':
                return 'OWi';
            case record.schadensklasse === 1:
                return 'Haftpflicht';
            case record.schadensklasse === 0:
                return 'Kasko';
            default:
                return '-';
        }
    }

    static recordHoursSaved(recordsTotal: number): number {
        return Math.round((recordsTotal * 140) / 60);
    }
}
