import { parseResponseInt } from '../funcs/map-entities';
import mapUser, {
    mapUsers,
    User,
} from '../../../../Modules/Crm/Resources/assets/react/mappers/userMapper';

export interface Office {
    name: string;
    unique: string;
    email: string;
    short: string;
    id: number;
    crm_restricted: boolean;
    crm_members?: User[];
    assigned_crm_user_id: string | null;
    assigned_crm_user?: User;
}

export interface OfficeFull extends Office {
    open_tasks_count: number;
    records_count: number;
}

const mapOffice = (res: any): Office => ({
    ...res,
    ...(res.crm_members && {
        crm_members: mapUsers(res.crm_members),
    }),
    ...(res.assigned_crm_user && {
        assigned_crm_user: mapUser(res.assigned_crm_user),
    }),
});

export const mapOffices = (res: any): Office[] => res.map(mapOffice);

export const mapOfficeFull = (res: any): OfficeFull => ({
    ...mapOffice(res),
    open_tasks_count: parseResponseInt(res.open_tasks_count),
    records_count: parseResponseInt(res.records_count),
});

export default mapOffice;
