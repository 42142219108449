import {
    mapOffices,
    Office,
} from '../../../../../../resources/assets/react/entities/Office';

export interface User {
    id: string;
    first_name: string;
    last_name: string;
    name: string;
    nameSortable: string;
    email: string;
    report_notification_order: 'date' | 'notification_date' | null;
    report_scope_selection: 'ownReports' | 'mainOfficeReports' | null;
    role: Role;
    roleTranslation: string;
    offices: Office[];
    isAdmin: boolean;
    isOfficeManager: boolean;
    permissions: string[];
    isDisabled: boolean;
    disabled_at: Date | null;
}

export type Role = 'admin' | 'office_manager' | 'user';

export const userRoles: { [key in Role]: string } = <const>{
    admin: 'Administrator',
    office_manager: 'Niederlassungsleiter',
    user: 'Benutzer',
};

const mapUser = (input: any): User => ({
    ...input,
    nameSortable: `${input.last_name}, ${input.first_name}`,
    role: input.roles?.length > 0 ? input.roles[0].name : null,
    roleTranslation:
        input.roles?.length > 0 ? userRoles[input.roles[0].name as Role] : '',
    isAdmin: input.roles?.length > 0 && input.roles[0].name === 'admin',
    isOfficeManager:
        input.roles?.length > 0 && input.roles[0].name === 'office_manager',
    permissions: input.permission_list
        ? input.permission_list.map(
              (permission: { name: string }) => permission.name
          )
        : [],
    disabled_at: input.disabled_at ? new Date(input.disabled_at) : null,
    ...(input.offices && {
        offices: mapOffices(input.offices),
    }),
});

export const mapUsers = (input: any): User[] => input.map(mapUser);

export default mapUser;
